import React from 'react';
import {
    Box,
    FormControl,
    FormHelperText,
    Grid, InputAdornment,
    InputLabel,
    MenuItem,
    Select, Slider,
    Switch,
    TextField,
    Typography,
} from '@mui/material';
import {AIInput} from 'components/index';
import {observer} from "mobx-react-lite";
import LocationBlock from "components/shared/LocationBlock/LocationBlock";
import projectDictionary from "assets/translations/Project/projectDictionary";
import FileLoader from "../../../../../../components/shared/FileLoader/FileLoader";
import utilitySystems from "../../../../../../assets/translations/utilitySystems";

const CommonForm = observer(({store}) => {
    const wrongSymbols = ['e', 'E', '-', '+',];

    return (
        <>
            <Box sx={{
                backgroundColor: 'white',
                padding: '20px',
                borderRadius: ' 0px 0px 10px 10px',
                width: '100%',
                boxSizing: 'border-box',
            }}>

                <Grid container spacing={'15px'}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Typography sx={{
                            fontSize:'16px',
                            fontWeight:'700',
                            marginBottom:'15px'
                        }}>
                            {projectDictionary.get('project_add_land_common_title')}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                    <Grid container spacing={'20px'}>
                    <Grid item xs={12} sm={6} md={4} lg={4}>
                        <TextField
                            placeholder={projectDictionary.get('project_add_land_name')}
                            label={projectDictionary.get('project_add_land_name')}
                            variant="outlined"
                            fullWidth
                            required
                            error={store.fieldsErrors?.name}
                            value={store.fields.name}
                            name={'name'}
                            onChange={store.handleInputChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={4}>
                        <TextField
                            placeholder={projectDictionary.get('project_add_land_areaTotal')}
                            label={projectDictionary.get('project_add_land_areaTotal')}
                            variant="outlined"
                            fullWidth
                            required
                            error={store.fieldsErrors?.project?.areaTotal}
                            value={store.fields.project.areaTotal}
                            name={'project.areaTotal'}
                            onChange={store.handleInputChange}
                            inputProps={{customType: 'area'}}
                            onKeyDown={(e) => {
                                if(wrongSymbols.includes(e.key)) {
                                    e.preventDefault()
                                }

                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={4}>
                        <TextField
                            placeholder={projectDictionary.get('project_add_land_marketValue')}
                            label={projectDictionary.get('project_add_land_marketValue')}
                            variant="outlined"
                            fullWidth
                            required
                            InputProps={{
                                endAdornment: <InputAdornment position="end">€</InputAdornment>,
                            }}
                            error={store.fieldsErrors?.project?.marketValue}
                            value={store.fields.project.marketValue}
                            name={'project.marketValue'}
                            onChange={store.handleInputChange}
                            inputProps={{customType: 'price'}}
                            onKeyDown={(e) => {
                                if(wrongSymbols.includes(e.key)) {
                                    e.preventDefault()
                                }

                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={4}>
                        <TextField
                            placeholder={projectDictionary.get('project_add_land_purpose')}
                            label={projectDictionary.get('project_add_land_purpose')}
                            variant="outlined"
                            fullWidth
                            error={store.fieldsErrors?.project?.purpose}
                            value={store.fields.project.purpose}
                            name={'project.purpose'}
                            onChange={store.handleInputChange}
                        />
                    </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={4}>
                            <FormControl variant="outlined" fullWidth>
                                <InputLabel id="select-type-label">
                                    {projectDictionary.get('project_add_utility')}
                                </InputLabel>
                                <Select
                                    variant="outlined"
                                    labelId="select-type-label"
                                    id="select-type"
                                    label={projectDictionary.get('project_add_utility')}
                                    multiple
                                    value={store.fields.project.utilitySystem}
                                    name={'project.utilitySystem'}
                                    onChange={(event) => {
                                        const value = event.target.value;
                                        store.handleInputField('project.utilitySystem',value)
                                    }}
                                >
                                    {utilitySystems.getAllValueLabel().map((item) => (
                                        <MenuItem key={item.value} value={item.value}>
                                            {item.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    </Grid>
                    <Grid item xs={12} >
                    <AIInput
                        placeholder={projectDictionary.get('project_add_land_locationDescription')}
                        label={`${projectDictionary.get('project_add_land_locationDescription')}`}
                        name={'project.locationDescription'}
                        value={store.fields.project.locationDescription}
                        onChange={store.handleInputChange}
                        maxTextLength={600}
                    />
                    </Grid>

                    <Grid item xs={12} >
                    <AIInput
                        placeholder={projectDictionary.get('project_add_land_concept')}
                        label={`${projectDictionary.get('project_add_land_concept')}`}
                        name={'project.concept'}
                        value={store.fields.project.concept}
                        onChange={store.handleInputChange}
                        maxTextLength={600}
                    />
                    </Grid>
                    <Grid item xs={12}>
                    <FileLoader
                        _data={store.fields.project}
                        _setData={(file)=>{
                            store.uploadFile('project.presentation',file.presentation)
                        }}
                        fileCount={store.fields?.project?.presentation?.url ? 1 : 0}
                        documentDropzone={true}
                        configs={{
                            field:'presentation',
                            confName:'project.construction',
                            documentDropzoneTitle: projectDictionary.get('project_add_presentation'),
                            documentDropzoneSubTitle:projectDictionary.get('file_loader_drop_sub_title')
                        }}

                    />
                    </Grid>

                </Grid>
            </Box>

            <Box
                sx={{backgroundColor: 'white', padding: '20px', borderRadius: '5px', marginTop: '20px', width: '100%'}}>
                <LocationBlock
                    data={store.fields}
                    setData={store.handleInputField}
                    errors={store?.fieldsErrors?.address}
                    field={'address'}
                />
            </Box>
        </>
    );
});

export default CommonForm;
