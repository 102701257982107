import React, { useState } from "react";
import circleInformationBlackIcon from "assets/icons/circle-information-black.svg";
import circleInformationIcon from "assets/icons/circle-information.svg";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import {tooltipClasses, Typography} from "@mui/material";
import { observer } from "mobx-react-lite";

const InfoIcon = observer(({ infoText, color }) => {
    const [open, setOpen] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const src = color === 'primary' ? circleInformationIcon : circleInformationBlackIcon;

    return (
        <Tooltip
            open={open}
            onOpen={handleOpen}
            onClose={handleClose}
            title={
                <Typography
                    component="p"
                    sx={{
                        borderRadius: '5px',
                        padding: '10px 20px',
                        minWidth: '130px',
                        maxWidth: '300px',
                        fontSize: '12px',
                        fontWeight: 400,
                        whiteSpace: 'normal',
                        overflowWrap: 'break-word',
                        textAlign: 'center',
                        lineHeight: '150%',
                    }}
                >
                    {infoText}
                </Typography>
            }
            componentsProps={{
                tooltip: {
                    sx: {
                        color: "#000000",
                        // Override MUI defaults
                        backgroundColor: '#F2EEE5',
                        [`& .${tooltipClasses.arrow}`]: {
                            color: "#F2EEE5",
                        },
                    },
                },
            }}
            placement="top"
            arrow
        >
            <IconButton
                onMouseEnter={handleOpen}
                onMouseLeave={handleClose}
                onClick={handleOpen} // Ensure it works on mobile as well
            >
                <img src={src} alt="info" />
            </IconButton>
        </Tooltip>
    );
});

export default InfoIcon;
