import React, {Fragment, useEffect} from 'react';
import {styled, useTheme} from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import {
    Box,
    Divider,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon, ListItemSecondaryAction,
    ListItemText,
    Modal,
    Typography,
    useMediaQuery,
} from '@mui/material';
import {useNavigate} from 'react-router-dom';
import globalDictionary from 'assets/translations/globalDictionary';
import CloseIcon from '@mui/icons-material/Close';

import complexIcon from 'assets/icons/complex.svg';
import propertyIcon from 'assets/icons/building.svg';
import projectIcon from 'assets/icons/building-NGO.svg';
import walletIcon from 'assets/icons/wallet.svg';
import exchangeIcon from 'assets/icons/refresh-cw-alt-3.svg';
import crmIcon from 'assets/icons/filter.svg';
import annotationUserIcon from 'assets/icons/annotation-user.svg';
import requestIcon from 'assets/icons/mail-search.svg';
import chatIcon from 'assets/icons/message-circle-chat.svg';
import communityIcon from 'assets/icons/users.svg';
import articlesIcon from 'assets/icons/file-alt.svg';
import supportIcon from 'assets/icons/user-question-alt-1.svg';
import contactsIcon from 'assets/icons/at-sign.svg';
import faqIcon from 'assets/icons/message-circle-question.svg';
import menuButtonIcon from 'assets/icons/menu-button.svg';
import knowledgeIcon from 'assets/icons/knowledge.svg';
import mailSearchIcon from 'assets/icons/mail-search.svg';
import {observer} from "mobx-react-lite";
import {checkRoles} from "utils";
import {useStore} from "Providers";
import {useWebsocketStore} from "../../../../Providers";
import Badge from "../../../../components/shared/Badge/Badge";

const DrawerMenu = observer(({handleDrawerOpen, handleDrawerClose, open, drawerWidth = 270}) => {
    const {auth, } = useStore();
    const websocket = useWebsocketStore()
    const navigate = useNavigate();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
    const appPath = window.location.pathname;
    const userRoles = auth?.user?.commItem?.type || [];
    const isCommunity = auth.user?.flags?.isCommunity && auth.user?.flags?.confirm;

    useEffect(() => {
        if (open && isSmallScreen) {
            handleDrawerClose();
        }
    }, [isSmallScreen]);

    // Constants for menu links
    let MENU_BLOCKS = [
        {
            items: [
                {
                    text: globalDictionary.get('layout_cabinet_drawer_menu_complexes'),
                    icon: complexIcon,
                    path:'complex',
                    link: '/complex',
                },
                {
                    text: globalDictionary.get('layout_cabinet_drawer_menu_properties'),
                    icon: propertyIcon,
                    path:'property',
                    link: '/property',
                },
                {
                    text: globalDictionary.get('layout_cabinet_drawer_menu_projects'),
                    icon: projectIcon,
                    link: '/project/hub',
                },
            ],
        },
        {
            items: [
                {
                    text: globalDictionary.get('layout_cabinet_drawer_menu_wallet'),
                    icon: walletIcon,
                    path:'wallet',
                    link: '/wallet',
                    condition: isCommunity
                },
                // {
                //     text: globalDictionary.get('layout_cabinet_drawer_menu_convert'),
                //     icon: exchangeIcon,
                //     link: '/soon',
                // },
            ],
        },
        {
            items: [
                {
                    text: globalDictionary.get('layout_cabinet_drawer_menu_crm'),
                    icon: crmIcon,
                    link: '/crm',
                    path: 'crm',
                    condition: checkRoles(userRoles, ['DevelopmentCompany', 'Broker', 'Seller']) && isCommunity,
                },
                {
                    text: globalDictionary.get('layout_cabinet_drawer_menu_request'),
                    icon: mailSearchIcon,
                    path:'smart',
                    link: '/smart',
                },
                {
                    text: globalDictionary.get('layout_cabinet_drawer_menu_ref'),
                    icon: annotationUserIcon,
                    path:'referrals',
                    link: '/referrals',
                },
                // {
                //     text: globalDictionary.get('layout_cabinet_drawer_menu_request'),
                //     icon: requestIcon,
                //     link: '/soon',
                // },
            ],
        },
        {
            items: [
                {
                    text: globalDictionary.get('layout_cabinet_drawer_menu_chat'),
                    icon: chatIcon,
                    path:'chat',
                    link: '/chat',
                    condition: isCommunity
                },
                {
                    text: globalDictionary.get('layout_cabinet_drawer_menu_community'),
                    icon: communityIcon,
                    link: '/community',
                },
                {
                    text: globalDictionary.get('layout_cabinet_drawer_menu_news'),
                    icon: articlesIcon,
                    path:'articles',
                    link: '/articles',
                },
            ],
        },
        {
            items: [
                {
                    text: globalDictionary.get('layout_cabinet_drawer_menu_support'),
                    icon: supportIcon,
                    path:'support',
                    link: '/support/tickets',
                },
                {
                    text: globalDictionary.get('layout_cabinet_drawer_menu_contact'),
                    icon: contactsIcon,
                    link: '/contact',
                },
                {
                    text: 'FAQ',
                    icon: faqIcon,
                    link: '/faq',
                },
                {
                    text: globalDictionary.get('layout_cabinet_drawer_menu_knowledge_base'),
                    icon: knowledgeIcon,
                    link: 'https://docs.grem.capital/',
                    external: true
                },
            ],
        },
    ]
        .filter((block) => block.items.some((item) => !item.hasOwnProperty('condition') || item.condition));

    const DrawerFooter = styled('div')(({theme}) => ({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginLeft: '5px',
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
    }));

    const openedMixin = (theme) => ({
        width: drawerWidth,
        border: 'none',
        justifyContent: 'space-between',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        overflowX: 'hidden',
    });

    const closedMixin = (theme) => ({
        border: 'none',
        justifyContent: 'space-between',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: `calc(${theme.spacing(9)} + 1px)`,
        [theme.breakpoints.up('sm')]: {
            width: `calc(${theme.spacing(9)} + 1px)`,
        },
    });

    const ModalDrawer = styled(MuiDrawer, {
        shouldForwardProp: (prop) => prop !== 'open',
    })(({theme, open}) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
        variant: 'temporary',
        open: open,
        onClose: handleDrawerClose,
    }));

    const Drawer = styled(MuiDrawer, {
        shouldForwardProp: (prop) => prop !== 'open',
    })(({theme, open}) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
        ...(isSmallScreen && {
            variant: 'temporary',
            open: open,
            onClose: handleDrawerClose,
        }),
    }));

    const CountBadge = ({count}) => {
        return (
            <Box sx={{
                width: open ?'31px' : '22px',
                height: '18px',
                borderRadius: '16px',
                background: '#E5494D',
                padding:'1px 0px 0px 0px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                position:!open&&'absolute',
                top:!open&&'0px',
                right:!open&&'0px',
            }}>
                <Typography sx={{
                    color: 'white',
                    fontSize: '11px',
                    fontWeight:'700',
                    lineHeight:'140%'
                }}>
                    {count > 99 ? '99+' : count}
                </Typography>
            </Box>
        )
    }

    const DrawerContent = ({
                               onItemClick = () => {
                               }
                           }) => (
        <Box
            sx={{
                marginTop: '80px',
            }}
        >

            {MENU_BLOCKS.map((block, blockIndex) => (
                <>
                    <List key={blockIndex}>
                        {block.items
                            .map((item) => (
                                <ListItem
                                    key={item.text}
                                    onClick={onItemClick}
                                    disablePadding
                                    disableGutters
                                    className={item.link === appPath ? 'active' : null}
                                    sx={{
                                        display: 'block',
                                        position: 'relative',
                                        color: item.important ? theme.palette.primary.main : 'initial',
                                        margin: '2px 5px',
                                        width: 'calc(100% - 10px)',
                                        borderRadius: '5px',
                                        '&:hover': {
                                            backgroundColor: theme.palette.primary.light,
                                        },
                                        '&.active': {
                                            backgroundColor: theme.palette.primary.light,
                                        },
                                    }}
                                >
                                    <ListItemButton
                                        sx={{
                                            display:'flex',
                                            alignItems:'center',
                                            minHeight: 48,
                                            justifyContent: open ? 'initial' : 'center',
                                            px: 2.5,
                                        }}
                                        onClick={() => {
                                            if (item.external) return window.open(item.link, '_blank')
                                            navigate(item.link);
                                        }}
                                    >
                                        <ListItemIcon
                                            sx={{
                                                minWidth: 0,
                                                mr: open ? '10px' : 'auto',
                                                justifyContent: 'center',
                                            }}
                                        >
                                            <img src={item.icon}/>
                                        </ListItemIcon>
                                        <ListItemText
                                            sx={{
                                                opacity: open ? 1 : 0,
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    color: '#021228',
                                                    fontSize: 14,
                                                    lineHeight: '120%',
                                                }}
                                            >
                                                {item.text}
                                            </Typography>
                                        </ListItemText>

                                        {item?.path &&
                                           <Badge path={item.path} open={open}/>
                                        }
                                    </ListItemButton>

                                </ListItem>
                            ))}
                    </List>
                    {blockIndex !== MENU_BLOCKS.length - 1 && <Divider/>}
                </>
            ))}
        </Box>
    );

    return (
        <>

            <Drawer variant={isSmallScreen ? 'temporary' : 'permanent'} open={open}>
                <DrawerContent/>
                <DrawerFooter>
                    <Box
                        onClick={open ? handleDrawerClose : handleDrawerOpen}
                        sx={{
                            width: '100%',
                            padding: open ? '0 28px 0 10px' : '5px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                    >
                        {open && (
                            <Typography
                                sx={{
                                    color: '#021228',
                                    fontSize: 14,
                                    lineHeight: '120%',
                                }}
                            >
                                {globalDictionary.get('layout_cabinet_drawer_menu_toggle')}
                            </Typography>
                        )}
                        <img
                            src={menuButtonIcon}
                            style={{
                                transition: 'all 0.1s ease',
                                transform: open ? 'rotate(0deg)' : 'rotate(180deg)',
                                cursor: 'pointer',
                            }}
                        />
                    </Box>
                </DrawerFooter>
            </Drawer>

            {isSmallScreen && (
                <Modal
                    open={open}
                    close={handleDrawerClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <ModalDrawer variant={'permanent'} open={open}>
                        <DrawerFooter>
                            <IconButton onClick={handleDrawerClose}>
                                <CloseIcon/>
                            </IconButton>
                        </DrawerFooter>
                        <DrawerContent onItemClick={handleDrawerClose}/>
                    </ModalDrawer>
                </Modal>
            )}
        </>
    );
});

export default DrawerMenu;
