import {observer} from "mobx-react-lite";
import {Box, Checkbox, FormControlLabel, Grid, TextField, Typography} from "@mui/material";
import React from "react";
import projectDictionary from "assets/translations/Project/projectDictionary";

const ParkingBlock = observer(({store}) => {

    const wrongSymbols = ['e', 'E', '-', '+', ',', '.'];
    return (
        <Box sx={{
            backgroundColor: 'white',
            padding: '20px',
            marginTop: '20px',
            borderRadius: '10px',
            width: '100%'
        }}>
            <Grid container spacing={'20px'}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Typography sx={{
                        fontSize: '16px',
                        fontWeight: '700',
                    }}>
                        {projectDictionary.get('project_add_parking_title')}
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                    <TextField
                        placeholder={projectDictionary.get(
                            'project_add_parking_total'
                        )}
                        label={projectDictionary.get('project_add_parking_total')}
                        variant="outlined"
                        fullWidth
                        value={store?.fields?.project?.parkingArea?.total}
                        name={'project.parkingArea.total'}
                        type={'number'}
                        inputProps={{customType:'area'}}

                        onChange={store.handleInputChange}
                    />
                </Grid>
                <Grid item xs={12} >

                    <Grid
                        container
                        direction={'row'}
                        spacing={'10px'}
                        alignItems={'center'}
                    >
                        <Grid item xs={12} sm={12} md={2}>
                            <FormControlLabel
                                sx={{marginLeft: '0px'}}
                                control={
                                    <Checkbox
                                        name={`project.parkingArea.undergroundParking.has`}
                                        value={store?.fields?.project?.parkingArea?.undergroundParking?.has}
                                        checked={store?.fields?.project?.parkingArea?.undergroundParking?.has}
                                        onChange={store.handleToggleCheckBox}
                                    />
                                } label={projectDictionary.get('project_add_parking_underground')}/>
                        </Grid>
                        {store?.fields?.project?.parkingArea?.undergroundParking?.has ?
                            <Grid item xs={12} sm={12} md={10}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6} md={4} lg={3}>
                                        <TextField
                                            placeholder={projectDictionary.get(
                                                'project_add_parking_area'
                                            )}
                                            label={projectDictionary.get('project_add_parking_area')}
                                            variant="outlined"
                                            fullWidth
                                            value={store?.fields?.project?.parkingArea?.undergroundParking?.total}
                                            name={`project.parkingArea.undergroundParking.total`}
                                            type={'number'}
                                            inputProps={{customType:'area'}}

                                            onChange={store.handleInputChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4} lg={3}>
                                        <TextField
                                            placeholder={projectDictionary.get(
                                                'project_add_parking_places'
                                            )}
                                            label={projectDictionary.get('project_add_parking_places')}
                                            variant="outlined"
                                            fullWidth
                                            value={store?.fields?.project?.parkingArea?.undergroundParking?.places}
                                            name={`project.parkingArea.undergroundParking.places`}
                                            type={'number'}
                                            inputProps={{customType:'int'}}

                                            onChange={store.handleInputChange}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            : ''
                        }
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid
                        container
                        direction={'row'}
                        spacing={'10px'}
                        alignItems={'center'}
                    >
                        <Grid item xs={12} sm={12} md={2}>
                            <FormControlLabel
                                sx={{marginLeft: '0px'}}
                                control={
                                    <Checkbox
                                        name={`project.parkingArea.groundParking.has`}
                                        value={store?.fields?.project?.parkingArea?.groundParking?.has}
                                        checked={store?.fields?.project?.parkingArea?.groundParking?.has}
                                        onChange={store.handleToggleCheckBox}
                                    />
                                } label={projectDictionary.get('project_add_parking_ground')}/>
                        </Grid>
                        {store?.fields?.project?.parkingArea?.groundParking?.has ?
                            <Grid item xs={12} sm={12} md={10}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6} md={4} lg={3}>
                                        <TextField
                                            placeholder={projectDictionary.get(
                                                'project_add_parking_area'
                                            )}
                                            label={projectDictionary.get('project_add_parking_area')}
                                            variant="outlined"
                                            fullWidth
                                            value={store?.fields?.project?.parkingArea?.groundParking?.total}
                                            name={`project.parkingArea.groundParking.total`}
                                            type={'number'}
                                            inputProps={{customType:'area'}}

                                            onChange={store.handleInputChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4} lg={3}>
                                        <TextField
                                            placeholder={projectDictionary.get(
                                                'project_add_parking_places'
                                            )}
                                            label={projectDictionary.get('project_add_parking_places')}
                                            variant="outlined"
                                            fullWidth
                                            value={store?.fields?.project?.parkingArea?.groundParking?.places}
                                            name={`project.parkingArea.groundParking.places`}
                                            type={'number'}
                                            inputProps={{customType:'int'}}

                                            onChange={store.handleInputChange}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            : ''
                        }

                    </Grid>
                </Grid>
            </Grid>
        </Box>
    )

})

export default ParkingBlock;