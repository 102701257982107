import {alpha} from '@mui/material/styles';

// ----------------------------------------------------------------------

export function overrides(theme) {
    return {
        MuiCssBaseline: {
            styleOverrides: {
                '*': {
                    boxSizing: 'border-box',
                },
                html: {
                    margin: 0,
                    padding: 0,
                    width: '100%',
                    height: '100%',
                    WebkitOverflowScrolling: 'touch',
                },
                body: {
                    margin: 0,
                    padding: 0,
                    width: '100%',
                    height: '100%',
                },
                '#root': {
                    width: '100%',
                    height: '100%',
                },
                input: {
                    '&[type=number]': {
                        MozAppearance: 'textfield',
                        '&::-webkit-outer-spin-button': {
                            margin: 0,
                            WebkitAppearance: 'none',
                        },
                        '&::-webkit-inner-spin-button': {
                            margin: 0,
                            WebkitAppearance: 'none',
                        },
                    },
                },
                img: {
                    maxWidth: '100%',
                    display: 'inline-block',
                    verticalAlign: 'bottom',
                },
            },
        },
        MuiBackdrop: {
            styleOverrides: {
                root: {
                    backgroundColor: alpha(theme.palette.grey[900], 0.8),
                },
                invisible: {
                    background: 'transparent',
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    fontSize: theme.typography.pxToRem(14),
                    fontWeight: theme.typography.fontWeightRegular,
                    lineHeight: 'normal',
                    borderRadius: '5px',
                    padding: '10px 20px',
                    boxShadow: 'none',
                    minHeight: 45,
                    '&:hover': {
                        boxShadow: 'none',
                        color: theme.palette.common.black,
                    },
                },
                text: {
                    color: '#C5A465',
                    '&:hover': {
                        color: theme.palette.common.black,
                    },
                },
                outlined: {
                    color: 'black',
                    borderColor: '#D1B684',
                    '&:hover': {
                        backgroundColor: '#D1B684',
                        color: theme.palette.common.black,
                    },
                }
            },
        },
        MuiSelect: {
            styleOverrides: {
                root: {
                    maxHeight: 45,
                },
            },
        },
        MuiTextField: {
            defaultProps: {
                onKeyDown: (event) => {
                    const fieldType = event.target.type;
                    if (fieldType === 'email' || fieldType === 'password') {
                        return;
                    }

                    const customType = event.target.getAttribute('customType');
                    const restrictSpecialChars = event.target.getAttribute('restrictSpecialChars');
                    if (restrictSpecialChars && !customType) {
                        const invalidKeys = ['!', '@', '#', '$', '%', '^', '&', '*', '(', ')', '=', '+', '[', ']', '{', '}', '\\', '|', ';', '\'', '\"', '/', '<', '>', '?', '`', '~', ',', '.'];
                        if (invalidKeys.includes(event.key)) {
                            event.preventDefault();
                        }
                    }

                    if (customType) {
                        const invalidKeys = ['e', 'E', '-', '+'];
                        if (invalidKeys.includes(event.key)) {
                            event.preventDefault();
                        }
                        if (customType === 'int' || customType === 'floor') {
                            if (event.key === '.' || event.key === ',') {
                                event.preventDefault();
                            }
                        }
                    }
                },
                onInput: (event) => {
                    const fieldType = event.target.type;
                    if (fieldType === 'email' || fieldType === 'password') {
                        return;
                    }

                    const customType = event.target.getAttribute('customType');
                    const restrictSpecialChars = event.target.getAttribute('restrictSpecialChars');
                    let value = event.target.value;

                    if (restrictSpecialChars && !customType) {
                        const sanitizedValue = value.replace(/[^a-zA-Z0-9а-яА-ЯёЁ\s]/g, '');
                        if (value !== sanitizedValue) {
                            event.target.value = sanitizedValue;
                        }
                    }

                    if (customType) {
                        const preventLeadingZero = () => {
                            if (value.length > 1 && parseFloat(value) === 0) {
                                event.target.value = value.slice(0, -1);
                            }
                        };

                        const restrictValue = (maxValue, regex) => {
                            if (parseFloat(value) > maxValue) {
                                event.target.value = maxValue.toString();
                            }
                            if (!regex.test(value)) {
                                event.target.value = value.slice(0, -1);
                            }
                        };

                        if (customType === 'price') {
                            restrictValue(9999999999.99, /^\d*\.?\d{0,2}$/);
                            preventLeadingZero();
                        } else if (customType === 'area') {
                            restrictValue(1000000.00, /^\d*\.?\d{0,2}$/);
                            preventLeadingZero();
                        } else if (customType === 'int') {
                            restrictValue(1000000, /^\d*$/);
                            preventLeadingZero();
                        } else if (customType === 'float') {
                            restrictValue(1000000.00, /^\d*\.?\d{0,2}$/);
                            preventLeadingZero();
                        }
                    }
                }
            },
            styleOverrides: {
                root: {
                    borderRadius: theme.shape.borderRadius,
                    borderColor: theme.palette.grey[200],
                    '& input': {
                        color: theme.palette.primary.contrastText,
                        padding: '9px 20px',
                    },
                    '& input::placeholder': {
                        color: theme.palette.grey[300],
                        opacity: 1,
                        '&:focus': {
                            color: theme.palette.grey[300],
                        },
                    },
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    minHeight: '45px',
                    fontSize: '14px',
                },
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderWidth: 1,
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#E0E0E0',
                    },
                },
                input: {
                    height: 'auto',
                    '&::placeholder': {
                        fontSize: '14px',
                    },
                }
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    top: '-5px',
                    '&.Mui-focused': {
                        color: theme.palette.primary.dark,
                    },
                    '&.MuiInputLabel-shrink': {
                        top: '0px'
                    },
                    '&.MuiInputLabel-outlined': {
                        color: '#A2A2A2',
                        fontSize: '14px'
                    },
                    '&.Mui-focused.MuiInputLabel-outlined': {
                        color: theme.palette.primary.main,
                    },
                },
            },
        },
        MuiPaginationItem: {
            styleOverrides: {
                root: {
                    color: theme.palette.primary.contrastText,
                    fontSize: theme.typography.pxToRem(16),
                    fontWeight: theme.typography.fontWeightRegular,
                    lineHeight: theme.typography.pxToRem(24),
                    margin: '0 10px',
                    '&:hover': {
                        backgroundColor: theme.palette.grey[100],
                    },
                    '&.Mui-selected': {
                        backgroundColor: theme.palette.grey[100],
                        color: theme.palette.primary.dark,
                        fontWeight: theme.typography.fontWeightBold,
                        '&:hover': {
                            backgroundColor: theme.palette.grey[200],
                        },
                    },
                },
                previousNext: {
                    backgroundColor: theme.palette.grey[100],
                    borderRadius: '20px',
                    height: '21px',
                    padding: '5px 14px',
                    '&:hover': {
                        backgroundColor: theme.palette.grey[200],
                    },
                },
                ellipsis: {
                    '&:hover': {
                        backgroundColor: 'transparent',
                    },
                },
            },
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    boxShadow: 'none',
                    borderRadius: '5px',
                    position: 'relative',
                    zIndex: 0,
                    cursor: 'pointer',
                    transition: theme.transitions.create(['box-shadow', 'transform']),
                    '&:hover': {
                        boxShadow: 'none',
                    },
                },
            },
        },
        MuiCardHeader: {
            defaultProps: {
                titleTypographyProps: {variant: 'h6'},
                subheaderTypographyProps: {variant: 'body2'},
            },
            styleOverrides: {
                root: {
                    padding: theme.spacing(3, 3, 0),
                },
            },
        },
        MuiPaper: {
            defaultProps: {
                elevation: 0,
            },
        },
        MuiTableContainer: {
            styleOverrides: {
                root: {
                    border: 'none',
                    borderRadius: '10px',
                },
            },
        },
        MuiTableCell: {
            styleOverrides: {
                head: {
                    padding: '8px 20px',
                    borderRadius: '5px 5px 0px 0px',
                    color: '#9299a2',
                    fontWeight: 700,
                    fontSize: '14px',
                    backgroundColor: theme.palette.background.neutral,
                },
                body: {
                    padding: '14px 20px',
                    fontSize: '14px',
                    color: theme.palette.text.primary,
                },
            },
        },
        MuiChip: {
            styleOverrides: {
                root: {
                    border: 'solid 1px #D0D0D0',
                    borderRadius: '40px'
                },
            }
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    backgroundColor: theme.palette.grey[800],
                },
                arrow: {
                    color: theme.palette.grey[800],
                },
            },
        },
        MuiTypography: {
            styleOverrides: {
                paragraph: {
                    marginBottom: theme.spacing(2),
                },
                gutterBottom: {
                    marginBottom: theme.spacing(1),
                },
            },
        },
        MuiMenu: {
            styleOverrides: {
                paper: {
                    boxShadow: 'none',
                    backgroundColor: '#FBFBFB',
                }
            }
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    fontSize: '12px !important',
                    boxShadow: 'none',
                    padding: '7px 10px'
                },
            },
        },
        MuiFormControlLabel: {
            styleOverrides: {
                label: {
                    color: '#7A7A7A',
                    fontSize: '14px',
                    lineHeight: '150%',
                    marginLeft: '10px'
                },
            },
        },
        MuiLink: {
            styleOverrides: {
                root: {
                    color: theme.palette.primary.dark,
                    '&:hover': {
                        textDecoration: 'none',
                    },
                },
            }
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    color: 'transparent',
                    width: '20px',
                    height: '20px',
                    border: 'solid 1px #C0C0C0',
                    borderRadius: '3px',
                    '& .MuiSvgIcon-root': {
                        fontSize: '24px',
                    },
                },
                colorPrimary: {
                    '&.Mui-checked': {
                        color: '#021228',
                    },
                },
            },
        },
        MuiRadio: {
            styleOverrides: {
                root: {
                    color: 'transparent',
                    width: '20px',
                    height: '20px',
                    border: 'solid 1px #C0C0C0',
                    marginRight: '10px',
                    '& .MuiSvgIcon-root': {
                        fontSize: '24px',
                    },
                },
                colorPrimary: {
                    '&.Mui-checked': {
                        color: '#021228',
                    },
                },
            },
        },
        MuiStep: {
            root: {
                backgroundColor: 'red',
                fill: 'red',
                color: 'red'
            },
        },
        MuiStepper: {
            styleOverrides: {
                root: {
                    [theme.breakpoints.down('sm')]: {
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        gap: '5px'
                    },
                },
            },
        },
        MuiCircularProgress: {
            styleOverrides: {
                root: {
                    color: theme.palette.primary.dark,
                },
            },
        },
        MuiContainer: {
            styleOverrides: {
                root: {
                    backgroundColor: 'white',
                    marginTop: theme.spacing(2),
                    padding: '20px',
                    borderRadius: '10px',
                    '&.MuiContainer-maxWidthLg': {
                        maxWidth: '1250px'
                    }
                },
            },
        },
        MuiStepLabel: {
            styleOverrides: {
                label: {
                    color: '#8B8B8B',
                    fontSize: '14px',
                    fontWeight: 'normal',
                },
            },
        },
        MuiStepConnector: {
            styleOverrides: {
                line: {
                    borderColor: '#CFCFCF',
                },
                lineHorizontal: {
                    borderTopStyle: 'solid',
                    borderTopWidth: '1px',
                },
                lineVertical: {
                    borderLeftStyle: 'solid',
                    borderLeftWidth: '1px',
                },
            },
        },
        MuiStepIcon: {
            styleOverrides: {
                root: {
                    color: '#E7E7E7',
                    borderRadius: '50%',
                    width: '35px',
                    height: '35px',
                    text: {
                        fontSize: '10px',
                        fill: '#8B8B8B',
                    },
                    '&.Mui-active': {
                        color: theme.palette.primary.dark,
                        text: {
                            fill: theme.palette.common.black,
                        },
                    },
                },
            },
        },
        MuiDivider: {
            styleOverrides: {
                root: {
                    borderColor: '#E0E0E0',
                }
            }
        },
        MuiTabs: {
            styleOverrides: {
                root: {
                    [theme.breakpoints.down('md')]: {
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        padding: '5px',
                        height: 'auto',
                        gap: '10px',
                        borderRadius: '20px',
                    },
                    padding: '5px',
                    height: '45px',
                    display: 'inline-flex',
                    alignItems: 'center',
                    gap: '20px',
                    borderRadius: '40px',
                    border: `1px solid ${theme.palette.grey[200]}`,
                },
                indicator: {
                    display: 'none',
                },
                flexContainer: {
                    display: 'flex',
                    [theme.breakpoints.down('md')]: {
                        flexDirection: 'column',
                    },
                },
                scroller: {
                    width: '100%',
                },
            }
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    [theme.breakpoints.down('md')]: {
                        maxWidth: '100%',
                    },
                    width: {sm: '100%'},
                    minHeight: '35px',
                    height: '35px',
                    border: '30px',
                    padding: '5px 21px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontWeight: 400,
                    color: '#8B8B8B',
                    transition: '0.2s',
                    '&.Mui-selected': {
                        backgroundColor: '#F0E8D9',
                        color: theme.palette.common.black,
                        fontWeight: 700,
                        borderRadius: '30px',
                    },
                    '&:hover': {
                        color: theme.palette.common.black,
                    },
                }
            }
        },
        MuiTabPanel: {
            styleOverrides: {
                root: {
                    padding: 0,
                }
            }
        },
        MuiAlert: {
            styleOverrides: {
                root: {
                    borderRadius: '10px',
                    padding: '14px 20px',
                    backgroundColor: '#F0E8D9',
                    width: '100%',
                    color: 'black',
                    fontSize: '18px',
                    lineHeight: '27px',
                },
                standardSuccess: {
                    backgroundColor: '#E8F7EE',
                },
                action: {
                    padding: 0,
                },
                outlinedWarning: {
                    border: '1px solid #C5A465',
                    backgroundColor: '#faf7f2',
                    fontSize: '16px',
                    lineHeight: '140%',
                    padding: '20px',
                    '& .MuiAlert-icon': {
                        display: 'none',
                    }
                },
                outlinedError: {
                    border: '1px solid #D80027',
                    backgroundColor: '#fef6f6',
                    fontSize: '16px',
                    lineHeight: '140%',
                    padding: '20px',
                    '& .MuiAlert-icon': {
                        display: 'none',
                    }
                }
            },
        },
        MuiListItem: {
            styleOverrides: {
                root: {
                    flexWrap: 'wrap',
                },
            },
        }
    };
}
