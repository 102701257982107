import projectDictionary from "../../../../../assets/translations/Project/projectDictionary";
import CommonForm from "../components/unit/CommonForm";
import PhotosForm from "../components/unit/PhotosForm";
import DocumentsForm from "../components/unit/DocumentsForm";
import commonDictionary from "../../../../../assets/translations/commonDictionary";
import TechnicalForm from "../components/unit/TechnicalForm";
import PlansForm from "../components/unit/PlansForm";
import TokenizationForm from "../components/unit/TokenizationForm";

class UnitStore{

    steps = [
        {
            label: () =>projectDictionary.get('common_step'),
            content: CommonForm,
        },
        {
            label: () =>projectDictionary.get('technical_step'),
            content: TechnicalForm,
        },
        {
            label: () =>projectDictionary.get('plans_step'),
            content: PlansForm,
        },
        {
            label: () =>projectDictionary.get('tokenization_step'),
            content: TokenizationForm,
        },
        {
            label: ()=>projectDictionary.get('documents_step'),
            content: DocumentsForm,
        },
    ];

    fields={
        units:[],
        areaTotal:'',
        utilitySystem:'',
        description:'',
        finalDate:Date.now(),
        houseClass:'',
        sections:'',
        floors:'',
        apartments:{
            count:'',
            condition:'',
            area:{
                from:'',
                to:''
            },
            apartType:[],
            furniture:false
        },
        parking:{
            total:'',
            undergroundParking:false,
            groundParking:false
        },

        plans:[],
        presentation:{},
        developmentCompanys: {
            participants:[],
            documents:[{}]
        },
        projectDocumentation:[{}],
        documents:{
            owner:true,
            ownerDocument:{},
            agreementDocument:{},
            experts:{},
            pledge:'',
            pledgeDocument:{},
            extra:[{
                document: {},
                description:''
            }]
        }
    }
    reqFields = [];


    constructor(props) {
        //makeAutoObservable(this)
        this.steps = [...props?.steps ?? [], ...this.steps]
        this.fields = {...props?.fields ?? {}, project:{...this.fields} }
        this.reqFields = [...props?.reqFields ?? '',...this.reqFields];

    }
}

export default UnitStore;