import React from 'react';
import {
    Box,
    FormControl,
    Grid,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
} from '@mui/material';

import {observer} from "mobx-react-lite";
import projectDictionary from "../../../../../../assets/translations/Project/projectDictionary";
import InfoIcon from "../../../../../../components/shared/InfoIcon/InfoIcon";

const FinancialForm = observer(({store}) => {
    //const store = useFormStore();
    const wrongSymbols = ['e', 'E', '-', '+'];
    return (
        <>
        <Box sx={{
            backgroundColor: 'white',
            padding: '20px',
            marginTop: '20px',
            borderRadius: '10px',
            width:'100%',
        }}>
            <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <Typography sx={{
                    fontSize:'16px',
                    fontWeight:'700'
                }}>
                    {projectDictionary.get('project_add_financial_title')}
                </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4}>
                <TextField
                    placeholder={projectDictionary.get('project_add_financial_self')}
                    label={projectDictionary.get('project_add_financial_self')}
                    variant="outlined"
                    fullWidth
                    value={store.fields.project.financial.self}
                    error={store.fieldsErrors?.project?.financial?.self}
                    helperText={store.fieldsErrors?.project?.financial?.self}
                    name={'project.financial.self'}
                    type={'text'}
                    inputProps={{customType:'price'}}
                    onChange={store.handleInputChange}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4}>
                <TextField
                    placeholder={projectDictionary.get('project_add_financial_credits')}
                    label={projectDictionary.get('project_add_financial_credits')}
                    variant="outlined"
                    fullWidth
                    value={store.fields.project.financial.credits}
                    error={store.fieldsErrors?.project?.financial?.credits}
                    helperText={store.fieldsErrors?.project?.financial?.credits}
                    name={'project.financial.credits'}
                    type={'text'}
                    inputProps={{customType:'price'}}
                    onChange={store.handleInputChange}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4}>
                <TextField
                    placeholder={projectDictionary.get('project_add_financial_arend')}
                    label={projectDictionary.get('project_add_financial_arend')}
                    variant="outlined"
                    fullWidth
                    value={store.fields.project.financial.arend}
                    error={store.fieldsErrors?.project?.financial?.arend}
                    helperText={store.fieldsErrors?.project?.financial?.arend}
                    name={'project.financial.arend'}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">
                            <InfoIcon
                                infoText={projectDictionary.get('project_add_financial_arend_info')}
                                color={'primary'}
                            />
                        </InputAdornment>,
                    }}

                    type={'text'}
                    inputProps={{customType:'price'}}
                    onChange={store.handleInputChange}
                />
            </Grid>

            </Grid>
        </Box>
        <Box sx={{
            backgroundColor: 'white',
            padding: '20px',
            marginTop: '20px',
            borderRadius: '10px',
            width:'100%',
        }}>
            <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <Typography sx={{
                    fontSize:'16px',
                    fontWeight:'700'
                }}>
                    {projectDictionary.get('project_add_financial_tokenization_title')}
                </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4}>
                <TextField
                    placeholder={projectDictionary.get('project_add_financial_tokenization_volume_attracted')}
                    label={projectDictionary.get('project_add_financial_tokenization_volume_attracted')}
                    variant="outlined"
                    fullWidth
                    required
                    value={store.fields.project.financial.volumeAttracted}
                    error={store.fieldsErrors?.project?.financial?.volumeAttracted}
                    helperText={store.fieldsErrors?.project?.financial?.volumeAttracted}
                    name={'project.financial.volumeAttracted'}
                    type={'text'}
                    inputProps={{customType:'price'}}

                    InputProps={{
                        endAdornment: <InputAdornment position="end">
                            <InfoIcon
                                infoText={projectDictionary.get('project_add_financial_tokenization_volume_attracted_info')}
                                color={'primary'}
                            />
                        </InputAdornment>,
                    }}
                    onChange={store.handleInputChange}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4}>
                <TextField
                    placeholder={projectDictionary.get('project_add_financial_tokenization_square')}
                    label={projectDictionary.get('project_add_financial_tokenization_square')}
                    variant="outlined"
                    fullWidth

                    value={store.fields.project.financial.square}
                    error={store.fieldsErrors?.project?.financial?.square}
                    helperText={store.fieldsErrors?.project?.financial?.square}
                    name={'project.financial.square'}
                    type={'text'}
                    inputProps={{customType:'area'}}
                    onChange={store.handleInputChange}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4}>
                <TextField
                    placeholder={projectDictionary.get('project_add_financial_tokenization_base_cost')}
                    label={projectDictionary.get('project_add_financial_tokenization_base_cost')}
                    variant="outlined"
                    fullWidth
                    value={store.fields.project.financial.baseCost}
                    error={store.fieldsErrors?.project?.financial?.baseCost}
                    helperText={store.fieldsErrors?.project?.financial?.baseCost}
                    name={'project.financial.baseCost'}
                    type={'text'}
                    inputProps={{customType:'price'}}
                    onChange={store.handleInputChange}
                />
            </Grid>

            </Grid>
        </Box>
    </>
            );
});

export default FinancialForm;
