import React, {useEffect, useState} from 'react';
import {Avatar} from '@mui/material';
import noImage from 'assets/images/no-image.png';
import {observer} from "mobx-react-lite";

const {REACT_APP_FILE_URL} = process.env
// Define the default style

const ImageChecker = observer(({src, variant, alt, imgProps, width, height, sx, text}) => {
    const [imgSrc, setImgSrc] = useState(null);
    const defaultStyle = {
        textAlign: 'center',
        height: height ? height : '100%',
        width: width ? width : '100%',
        overflow: 'hidden',
        borderRadius: '5px',
        objectFit: 'cover'
    };

    useEffect(() => {
        if (!src) return;


        if (Array.isArray(src)) {

            const mainItem = src.find(item => item.isMain);

            if (mainItem) {
                if (typeof mainItem.url === 'string') {
                    if (mainItem.url.includes('http') || mainItem.url.includes('https') || mainItem.url.includes('data:')) {
                        return setImgSrc(mainItem.url);
                    }
                    return setImgSrc(`${REACT_APP_FILE_URL}${mainItem.url}`);
                } else if (mainItem.url instanceof File) {
                    return setImgSrc(URL.createObjectURL(mainItem.url));
                }
            }

            return setImgSrc(src?.[0]?.url ? `${REACT_APP_FILE_URL}${src?.[0]?.url}`: '');
        }


        if (typeof src === 'string') {
            if (src.includes('http') ||src.includes('https') || src.includes('data:')) {
                return setImgSrc(src);
            }
            setImgSrc(`${REACT_APP_FILE_URL}${src}`);
        } else if (src instanceof File) {
            setImgSrc(URL.createObjectURL(src));
        } else {
            setImgSrc('');
        }
    }, [src]);


    const handleError = (event) => {
        setImgSrc(noImage);
    };

    return (
        <Avatar
            src={imgSrc || noImage}
            variant={variant}
            alt={alt || 'image'}
            sx={{
                ...defaultStyle,
                ...sx,
            }}
            slotProps={imgProps || {}}
            onError={handleError}
        >
            {text || ''}
        </Avatar>
    );
})

export default ImageChecker;
