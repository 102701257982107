
import CommonForm from "../components/construction/CommonForm";
import TechnicalForm from "../components/construction/TechnicalForm";
import FinancialForm from "../components/construction/FinancialForm";
import PhotosForm from "../components/construction/PhotosForm";
import DocumentsForm from "../components/construction/DocumentsForm";
import projectDictionary from "../../../../../assets/translations/Project/projectDictionary";
import {makeAutoObservable} from "mobx";

class ConstructionStore{

    steps = [
        {
            label: () =>projectDictionary.get('common_step'),
            content: CommonForm,
        },
        {
            label: ()=>projectDictionary.get('technical_step'),
            content: TechnicalForm,
        },
        {
            label: ()=>projectDictionary.get('financial_step'),
            content: FinancialForm,
        },
        {
            label: ()=>projectDictionary.get('photo_step'),
            content: PhotosForm,
        },
        {
            label: ()=>projectDictionary.get('documents_step'),
            content: DocumentsForm,
        },
    ];
    fields = {
        owner:'',
        builder:'',
        architect:'',
        stage:'15',
        description:'',
        advantage:'',
        social:'',
        presentation:{},
        projectType:[],
        floors:'',
        finalDate:Date.now(),
        area:{
            total:'',
            building:'',
            effective:'',
            land:''
        },
        liveArea:{
            total:'',
            one:false,
            two:false,
            three:false,
            four:false,
            five:false,
            penthouse:false,
            villa:false,
            studio:false,
            count:{
                one:'',
                two:'',
                three:'',
                four:'',
                five:'',
                penthouse:'',
                villa:'',
                studio:'',
            }
        },
        officeArea:{
            total:'',
            effective:'',
            rooms:'',
            storeys:'',
            floorHeight:'',
            lift:false,
            utilitySystem: [],
        },
        restaurantCafeArea:{
            total:'',
            effective:'',
            seats:'',
            kitchen:'',
            store:'',
            storeNumber:'',
            cabinets:'',
            cabinetsNumbers:'',
            storeys:'',
            floor:'',
            floorHeight:'',
            lift:false,
            utilitySystem: [],
        },
        storeProductionArea:{
            total:'',
            land:'',
            effective:'',
            production:'',
            admin:'',
            store:'',
            storeys:'',
            floorHeight:'',
            lift:false,
            utilitySystem: [],
        },
        hotelArea:{
            total:'',
            land:'',
            numberFond:'',
            numbers:'',
            peoples:'',
            common:'',
            storeys:'',
            floorHeight:'',
            lift:false,
            utilitySystem: [],
        },
        parkingArea:{
            total:'',
            undergroundParking:{
                has:false,
                total:'',
                places:''
            },
            groundParking:{
                has:false,
                total:'',
                places:''
            }
        },
        financial:{
            self:'',
            credits:'',
            arend:'',
            volumeAttracted:'',
            square:'',
            baseCost:''
        },
        documents:{
            owner:true,
            ownerDocument:{},
            agreementDocument:{},
            landPlan:[],
            zoningPlan:[],
            experts:{},
            permission:true,
            permissionDocument: {},
            construction:true,
            constructionPlan:{},
            pledge:'',
            pledgeDocument:{},
            extra:[{
                document: {},
                description: ''
            }] //{document: {},description:''}

    }
    }
    reqFields = [];

    constructor(props) {
        //makeAutoObservable(this)
        this.steps = [...props?.steps ?? [], ...this.steps]
        this.fields = {...props?.fields ?? {}, project:{...this.fields} }
        this.reqFields = [...props?.reqFields ?? '',...this.reqFields];

    }



}

export default ConstructionStore;