import {observer} from "mobx-react-lite";
import {Box, Checkbox, FormControlLabel, Grid, TextField, Typography} from "@mui/material";
import apartsType from "assets/translations/apartsType";
import React from "react";
import projectDictionary from "assets/translations/Project/projectDictionary";

const LiveBlock = observer(({store}) => {

    const wrongSymbols = ['e', 'E', '-', '+', ',', '.'];
    return (
        <Box sx={{
            backgroundColor: 'white',
            padding: '20px',
            marginTop: '20px',
            borderRadius: '10px',
            width: '100%'
        }}>
            <Grid container spacing={'20px'}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <Typography sx={{
                    fontSize: '16px',
                    fontWeight: '700',
                }}>
                    {projectDictionary.get('project_add_liveArea_title')}
                </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4}>
                <TextField
                    placeholder={projectDictionary.get(
                        'project_add_liveArea_total'
                    )}
                    label={projectDictionary.get('project_add_liveArea_total')}
                    variant="outlined"
                    fullWidth
                    value={store?.fields?.project?.liveArea?.total}
                    name={'project.liveArea.total'}
                    type={'number'}
                    inputProps={{customType: 'area'}}

                    onChange={store.handleInputChange}
                />
            </Grid>
            {/*  ApartBlock  */}
            <Grid item xs={12} >
                    <Grid container columnSpacing={'40px'}>
                        {apartsType.getAllValueLabel().map((item) => {
                            return <Grid item xs={12} sm={6} md={6} lg={4}>
                                <Grid container alignItems={'center'}>
                                    <Grid item xs={12} sm={6}>
                                        <FormControlLabel
                                            sx={{marginLeft:'0px',marginTop:'22.5px',marginBottom:'22.5px'}}
                                            control={
                                                <Checkbox
                                                    name={`project.liveArea.${item.langValue ? item.langValue : item.value}`}
                                                    value={store?.fields?.project?.liveArea?.[item.langValue ? item.langValue : item.value]}
                                                    checked={store?.fields?.project?.liveArea?.[item.langValue ? item.langValue : item.value]}
                                                    onChange={store.handleToggleCheckBox}
                                                />
                                            } label={item.label}/>
                                    </Grid>
                                    {store?.fields?.project?.liveArea?.[item.langValue ? item.langValue : item.value] ?
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                placeholder={projectDictionary.get(
                                                    'project_add_liveArea_count'
                                                )}
                                                label={projectDictionary.get('project_add_liveArea_count')}
                                                variant="outlined"
                                                fullWidth
                                                value={store?.fields?.project?.liveArea?.count[item.langValue ? item.langValue : item.value]}
                                                name={`project.liveArea.count.${item.langValue ? item.langValue : item.value}`}
                                                type={'number'}
                                                inputProps={{customType: 'int'}}
                                                onChange={store.handleInputChange}
                                            />
                                        </Grid>
                                        : ''
                                    }
                                </Grid>
                            </Grid>
                        })}

                    </Grid>
            </Grid>
            </Grid>
        </Box>
    )

})

export default LiveBlock;