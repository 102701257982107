import io from 'socket.io-client';
import { wsServer } from '../config';
import {makeAutoObservable, observable, runInAction} from "mobx";

class NotificationSocketService {
  socket = null;
  notifications = observable.array([]); // Массив для хранения уведомлений
  currentPage = 1;
  totalPages = 1;
  loading = false;
  counter = 0;
  badges = new Map(); // Map для кумулятивных баджей
  unreadCount = 0;

  constructor() {
    makeAutoObservable(this);
  }

  // Методы для отправки событий на сервер
  newItemShowed = (pageName, countType) => {
    if (this.socket) {
      this.socket.emit('newItemsShowed', { pageName, countType });
    }
  };

  getNotifications = (userId, currentPage, pageLimit) => {
    if (this.socket) {
      runInAction(() => {
        this.loading = true;
      });
      this.socket.emit('getNotifications', { userId, currentPage, pageLimit });
    }
  };

  newItemShowedBadge = (pageName) => {
    if (this.socket) {
      this.socket.emit('newItemsShowedBadge', { pageName });
    }
  };

  readMessages = ({ id, pageName, count }) => {
    if (this.socket) {
      this.socket.emit('messagesRead', { id, pageName, count });
    }
  };

  setAllNotificationsRead = (userId) => {
    if (this.socket) {
      this.socket.emit('setAllNotificationsRead', { userId });
    }
  };

  buildCumulativeBadgeMap = (data) => {
    const badgeMap = new Map();

    Object.entries(data).forEach(([path, badge]) => {
      const parts = path.split(':');

      // Для каждого значения в объекте badge проверяем, является ли оно числом
      Object.values(badge).forEach((value) => {
        if (typeof value === 'number') {
          for (let i = 1; i <= parts.length; i++) {
            const subPath = parts.slice(0, i).join(':');
            badgeMap.set(subPath, (badgeMap.get(subPath) || 0) + value);
          }
        }
      });
    });

    // Вычисляем сумму всех первых сегментов
    let total = 0;
    badgeMap.forEach((value, key) => {
      if (!key.includes(':')) { // Проверяем, является ли ключ первым сегментом
        total += value;
      }
    });
    badgeMap.set('total', total); // Добавляем ключ 'total'

    return badgeMap;
  };

  // Инициализация подключения к WebSocket
  async init(token) {
    return new Promise((resolve, reject) => {
      if (token) {
        this.socket = io(`${wsServer}/notification`, {
          transports: ['websocket'],
          query: {
            token,
          },
        });

        this.socket.on('connect', () => {
          console.log('Подключено к WebSocket серверу');
          resolve(this);
        });

        this.socket.on('counters', (data) => {
          runInAction(() => {
            this.counter = data || 0;
            this.badges = this.buildCumulativeBadgeMap(data || {});
          });
        });

        this.socket.on('notifications', (data) => {
          runInAction(() => {
            this.notifications.replace(data.data);
            this.currentPage = data.currentPage;
            this.totalPages = data.totalPages;
            this.unreadCount = data.unreadCount;
            this.loading = false;
          });
        });

        this.socket.on('notificationsUpdated', (data) => {
          runInAction(() => {
            this.notifications.replace(data.data);
            this.currentPage = data.currentPage;
            this.totalPages = data.totalPages;
            this.unreadCount = data.unreadCount;
          });
        });


        this.socket.on('error', (error) => {
          console.error('Ошибка соединения с WebSocket:', error);
          reject(error);
        });

        this.socket.on('connect_error', (error) => {
          console.error('Ошибка подключения к WebSocket:', error);
          reject(error);
        });
      } else {
        reject(new Error('Токен не предоставлен'));
      }
    });
  }

  // Метод для обновления баджей (может использоваться для динамического обновления)
  updateBadges = (newData) => {
    runInAction(() => {
      const updatedBadges = this.buildCumulativeBadgeMap(newData);
      this.badges = updatedBadges;
    });
  };
}

export default new NotificationSocketService();
