import {observer} from "mobx-react-lite";
import {Box, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography} from "@mui/material";
import React from "react";
import projectDictionary from "assets/translations/Project/projectDictionary";
import utilitySystems from "../../../../../../../assets/translations/utilitySystems";
import commonDictionary from "../../../../../../../assets/translations/commonDictionary";

const StoreProductionBlock = observer(({store})=>{

    const wrongSymbols = ['e', 'E', '-', '+', ',', '.'];
    return(
        <Box sx={{
            backgroundColor: 'white',
            padding: '20px',
            marginTop: '20px',
            borderRadius: '10px',
            width:'100%'
        }}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Typography sx={{
                        fontSize:'16px',
                        fontWeight:'700',
                    }}>
                        {projectDictionary.get('project_add_store_production_title')}
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                    <TextField
                        placeholder={projectDictionary.get(
                            'project_add_store_production_total'
                        )}
                        label={projectDictionary.get('project_add_store_production_total')}
                        variant="outlined"
                        fullWidth
                        value={store?.fields?.project?.storeProductionArea?.total}
                        name={`project.storeProductionArea.total`}
                        type={'number'}
                        inputProps={{customType:'area'}}

                        onChange={store.handleInputChange}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                    <TextField
                        placeholder={projectDictionary.get(
                            'project_add_store_production_land'
                        )}
                        label={projectDictionary.get('project_add_store_production_land')}
                        variant="outlined"
                        fullWidth
                        value={store?.fields?.project?.storeProductionArea?.land}
                        name={`project.storeProductionArea.land`}
                        type={'number'}
                        inputProps={{customType:'area'}}

                        onChange={store.handleInputChange}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                    <TextField
                        placeholder={projectDictionary.get(
                            'project_add_store_production_effective'
                        )}
                        label={projectDictionary.get('project_add_store_production_effective')}
                        variant="outlined"
                        fullWidth
                        value={store?.fields?.project?.storeProductionArea?.effective}
                        name={`project.storeProductionArea.effective`}
                        type={'number'}
                        inputProps={{customType:'area'}}

                        onChange={store.handleInputChange}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                    <TextField
                        placeholder={projectDictionary.get(
                            'project_add_store_production_production'
                        )}
                        label={projectDictionary.get('project_add_store_production_production')}
                        variant="outlined"
                        fullWidth
                        value={store?.fields?.project?.storeProductionArea?.production}
                        name={`project.storeProductionArea.production`}
                        type={'number'}
                        inputProps={{customType:'area'}}

                        onChange={store.handleInputChange}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                    <TextField
                        placeholder={projectDictionary.get(
                            'project_add_store_production_admin'
                        )}
                        label={projectDictionary.get('project_add_store_production_admin')}
                        variant="outlined"
                        fullWidth
                        value={store?.fields?.project?.storeProductionArea?.admin}
                        name={`project.storeProductionArea.admin`}
                        type={'number'}
                        inputProps={{customType:'area'}}

                        onChange={store.handleInputChange}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                    <TextField
                        placeholder={projectDictionary.get(
                            'project_add_store_production_store'
                        )}
                        label={projectDictionary.get('project_add_store_production_store')}
                        variant="outlined"
                        fullWidth
                        value={store?.fields?.project?.storeProductionArea?.store}
                        name={`project.storeProductionArea.store`}
                        type={'number'}
                        inputProps={{customType:'area'}}

                        onChange={store.handleInputChange}
                    />
                </Grid>


                <Grid item xs={12} sm={6} md={4} lg={4}>
                    <TextField
                        placeholder={projectDictionary.get(
                            'project_add_storeys'
                        )}
                        label={projectDictionary.get('project_add_storeys')}
                        variant="outlined"
                        fullWidth
                        value={store?.fields?.project?.storeProductionArea?.storeys}
                        name={`project.storeProductionArea.storeys`}
                        type={'number'}
                        inputProps={{customType:'int'}}

                        onChange={store.handleInputChange}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                    <TextField
                        placeholder={projectDictionary.get(
                            'project_add_floorHeight'
                        )}
                        label={projectDictionary.get('project_add_floorHeight')}
                        variant="outlined"
                        fullWidth
                        value={store?.fields?.project?.storeProductionArea?.floorHeight}
                        name={`project.storeProductionArea.floorHeight`}
                        type={'number'}
                        inputProps={{customType:'float'}}

                        onChange={store.handleInputChange}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                    <FormControl variant="outlined" fullWidth>
                        <InputLabel id="select-type-label">
                            {projectDictionary.get('project_add_lift')}
                        </InputLabel>
                        <Select
                            variant="outlined"
                            labelId="select-type-label"
                            id="select-type"
                            label={projectDictionary.get('project_add_lift')}
                            value={store?.fields?.project?.storeProductionArea?.lift?.toString()}
                            name={'project.storeProductionArea.lift'}
                            onChange={(event) => {
                                const value = event.target.value;
                                store.handleInputField('project.storeProductionArea.lift',value==='true')
                            }}
                        >
                            <MenuItem key={'yes'} value={'true'}>
                                {commonDictionary.get('yes')}
                            </MenuItem>
                            <MenuItem key={'no'} value={'false'}>
                                {commonDictionary.get('no')}
                            </MenuItem>

                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                    <FormControl variant="outlined" fullWidth>
                        <InputLabel id="select-type-label">
                            {projectDictionary.get('project_add_utility')}
                        </InputLabel>
                        <Select
                            variant="outlined"
                            labelId="select-type-label"
                            id="select-type"
                            label={projectDictionary.get('project_add_utility')}
                            multiple
                            value={store?.fields?.project?.storeProductionArea?.utilitySystem}
                            name={'project.storeProductionArea.utilitySystem'}
                            onChange={(event) => {
                                const value = event.target.value;
                                store.handleInputField('project.storeProductionArea.utilitySystem',value)
                            }}
                        >
                            {utilitySystems.getAllValueLabel().map((item) => (
                                <MenuItem key={item.value} value={item.value}>
                                    {item.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>

            </Grid>
        </Box>
    )
})

export default StoreProductionBlock;