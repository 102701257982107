const ImageConfigs = {
    defaultConfigs: {
        field: 'photo',
        titleKey: 'image_uploader_title',
        minCount: 0,
        count: 1,
        size: 5,
    },
    userConfigs: {
        avatar: {
            minCount: 1,
            maxCount: 1,
            size: 15,
        }
    },
    communityConfigs: {
        license: {
            minCount: 0,
            maxCount: 15,
            scan: {
                minCount: 1,
                maxCount: 1,
                size: 15,
            },
        },
        education: {
            minCount: 0,
            maxCount: 15,
            scan: {
                minCount: 1,
                maxCount: 1,
                size: 15,
            },
        },
        portfolio: {
            minCount: 0,
            maxCount: 20,
            size: 15,
        },
        logo: {
            minCount: 0,
            maxCount: 1,
            size: 15,
        },
        certificateScan: {
            minCount: 0,
            maxCount: 5,
            size: 15,
        },
        img: {
            minCount: 0,
            maxCount: 5,
            size: 15,
        },
        documentsPhoto: {
            minCount: 0,
            maxCount: 5,
            size: 15,
        },
    },
    complexConfigs: {
        mainPhoto: {
            minCount: 1,
            maxCount: 1,
            size: 15,
        },
        photos: {
            minCount: 1,
            maxCount: 25,
            size: 10,
        },
        documentsLand: {
            minCount: 1,
            maxCount: 20,
            size: 10,
        },
        documentsConstruction: {
            minCount: 1,
            maxCount: 20,
            size: 10,
        },
        constructionParticipants: {
            minCount: 1,
            maxCount: 20,
            size: 10,
        },
        images: {
            minCount: 1,
            maxCount: 20,
            size: 10,
        },
    },
    apartConfigs: {
        images: {
            minCount: 1,
            maxCount: 20,
            size: 10,
        },
    },
    progressConfigs: {
        images: {
            minCount: 0,
            maxCount: 20,
            size: 10,
        },
    },
    projectsConfig: {
        init: {
            loi: {
                minCount: 1,
                maxCount: 1,
                size: 20,
            },
            nda: {
                minCount: 1,
                maxCount: 1,
                size: 20,
            }
        },
        mainPhoto: {
            minCount: 1,
            maxCount: 1,
            size: 15,
        },
        photos: {
            minCount: 1,
            maxCount: 25,
            size: 10,
        },
        ownershipDocuments: {
            minCount: 1,
            maxCount: 10,
            size: 10,
        },
        projectDocumentation: {
            minCount: 1,
            maxCount: 10,
            size: 10,
        },
        buildingPermit: {
            minCount: 1,
            maxCount: 10,
            size: 10,
        },
        businessPlan: {
            minCount: 1,
            maxCount: 10,
            size: 10,
        },
        dueDiligence: {
            minCount: 1,
            maxCount: 10,
            size: 10,
        },
        restrictions: {
            minCount: 1,
            maxCount: 10,
            size: 10,
        },
    },
    propertyConfigs: {
        plans: {
            minCount: 0,
            maxCount: 10,
            size: 15,
        },
        photos: {
            minCount: 1,
            maxCount: 25,
            size: 10,
        },
        documents: {
            owner: {
                minCount: 0,
                maxCount: 10,
                size: 10,
            },
            agency: {
                minCount: 0,
                maxCount: 10,
                size: 10,
            }
        }
    },

    newComplexConfigs: {
        complexPhotos: {
            minCount: 2,
            maxCount: 25,
            size: 10,
        },
        logo: {
            minCount: 0,
            maxCount: 10,
            size: 10,
        },
        floorPhotos: {
            minCount: 1,
            maxCount: 10,
            size: 10,
        },
        apartPhotos: {
            minCount: 1,
            maxCount: 10,
            size: 10,
        },
        progressPhotos: {
            minCount: 0,
            maxCount: 10,
            size: 10,
        },
        newsPhotos: {
            minCount: 0,
            maxCount: 10,
            size: 10,
        },
        land: {
            minCount: 0,
            maxCount: 10,
            size: 10,
        },
        participants: {
            minCount: 0,
            maxCount: 10,
            size: 10,
        },
        construction: {
            minCount: 0,
            maxCount: 10,
            size: 10,
        }
    },
    support: {
        attachments: {
            minCount: 0,
            maxCount: 1,
            size: 10
        }
    },
    contracts: {
        userScan: {
            minCount: 0,
            maxCount: 1,
            size: 10
        }
    },
    project: {
        newsPhotos:{
            minCount: 0,
            maxCount: 10,
            size: 10,
        },
        progressPhotos:{
            minCount: 0,
            maxCount: 10,
            size: 10,
        },
        construction: {
            presentation: {
                minCount: 0,
                maxCount: 1,
                size: 100,
                types:['PDF', 'PPTX', 'DOC', 'DOCX', 'RTF', 'JPEG', 'JPG', 'PNG', 'WEBP']
            },
            photos:{
                minCount: 1,
                maxCount: 25,
                size: 10
            },
            documents: {
                ownerDocument: {
                    minCount: 0,
                    maxCount: 1,
                    size: 10,
                },
                agreementDocument: {
                    minCount: 0,
                    maxCount: 1,
                    size: 10,
                },
                landPlan: {
                    minCount: 0,
                    maxCount: 10,
                    size: 10,
                },
                zoningPlan: {
                    minCount: 0,
                    maxCount: 10,
                    size: 10,
                },
                permissionDocument: {
                    minCount: 0,
                    maxCount: 1,
                    size: 10,
                },
                constructionPlan: {
                    minCount: 0,
                    maxCount: 1,
                    size: 10,
                },
                pledgeDocument: {
                    minCount: 0,
                    maxCount: 1,
                    size: 10,
                },
                experts: {
                    minCount: 0,
                    maxCount: 1,
                    size: 10,
                },
                extra: {
                    minCount: 0,
                    maxCount: 1,
                    size: 10,
                },
            }
        },
        land: {
            presentation: {
                minCount: 0,
                maxCount: 1,
                size: 100,
                types:['PDF', 'PPTX', 'DOC', 'DOCX', 'RTF', 'JPEG', 'JPG', 'PNG', 'WEBP']
            },
            photos:{
                minCount: 1,
                maxCount: 25,
                size: 10
            },
            visualization:{
                minCount: 0,
                maxCount: 25,
                size: 10
            },
            documents: {
                ownerDocument: {
                    minCount: 0,
                    maxCount: 1,
                    size: 10,
                },
                agreementDocument: {
                    minCount: 0,
                    maxCount: 1,
                    size: 10,
                },
                landPlan: {
                    minCount: 0,
                    maxCount: 10,
                    size: 10,
                },
                zoningPlan: {
                    minCount: 0,
                    maxCount: 10,
                    size: 10,
                },
                permissionDocument: {
                    minCount: 0,
                    maxCount: 1,
                    size: 10,
                },
                constructionPlan: {
                    minCount: 0,
                    maxCount: 1,
                    size: 10,
                },
                pledgeDocument: {
                    minCount: 0,
                    maxCount: 1,
                    size: 10,
                },
                experts: {
                    minCount: 0,
                    maxCount: 1,
                    size: 10,
                },
                extra: {
                    minCount: 0,
                    maxCount: 1,
                    size: 10,
                },
            }
        },
        unit: {
            presentation: {
                minCount: 0,
                maxCount: 1,
                size: 100,
                types:['PDF', 'PPTX', 'DOC', 'DOCX', 'RTF', 'JPEG', 'JPG', 'PNG', 'WEBP']
            },
            photos:{
                minCount: 1,
                maxCount: 25,
                size: 10
            },
            plans:{
                minCount: 0,
                maxCount: 25,
                size: 10
            },
            floorPhotos:{
                minCount: 1,
                maxCount: 25,
                size: 10
            },
            developmentCompanys:{
                documents: {
                    minCount: 0,
                    maxCount: 1,
                    size: 10
                }
            },
            projectDocumentation:{
                minCount: 0,
                maxCount: 1,
                size: 10
            },
            documents: {
                ownerDocument: {
                    minCount: 0,
                    maxCount: 1,
                    size: 10,
                },
                agreementDocument: {
                    minCount: 0,
                    maxCount: 1,
                    size: 10,
                },
                landPlan: {
                    minCount: 0,
                    maxCount: 10,
                    size: 10,
                },
                zoningPlan: {
                    minCount: 0,
                    maxCount: 10,
                    size: 10,
                },
                permissionDocument: {
                    minCount: 0,
                    maxCount: 1,
                    size: 10,
                },
                constructionPlan: {
                    minCount: 0,
                    maxCount: 1,
                    size: 10,
                },
                pledgeDocument: {
                    minCount: 0,
                    maxCount: 1,
                    size: 10,
                },
                experts: {
                    minCount: 0,
                    maxCount: 1,
                    size: 10,
                },
                extra: {
                    minCount: 0,
                    maxCount: 1,
                    size: 10,
                },
            }
        }
    },
    get: function (name, field) {
        const config = _.get(this, name);

        if (!config) {
            return undefined; // Если конфиг не найден, возвращаем undefined
        }

        const fieldParts = field.split('.');
        let configLast = config;

        // Начинаем с самого длинного совпадения
        for (let i = fieldParts.length; i >= 0; i--) {
            const partialField = fieldParts.slice(0, i).join('.');
            const partialConfig = _.get(config, partialField);

            if (partialConfig) {
                configLast = partialConfig;
                break; // Нашли удачное совпадение, выходим из цикла
            }
        }

        return configLast;
    },


    verifyImages: function (configPath, count) {
        const config = _.get(this, configPath);
        return !(count >= config.minCount && count <= config.maxCount);
    }
};

export default ImageConfigs;
