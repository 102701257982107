import {makeAutoObservable} from "mobx";
import {GremService} from "services";

class LanguageStore {
    activeLanguage = localStorage.getItem('language') ?? 'eng';

    constructor() {
        makeAutoObservable(this);
    }

    setActiveLanguage(lang) {
        this.activeLanguage = lang;
        localStorage.setItem('language', lang);
        let userLang = 'en'
        switch (lang) {
            case 'rus': {
                userLang = 'ru';
                break;
            }
            case 'ukr': {
                userLang = 'ua';
                break;
            }
            case 'deu': {
                userLang = 'de';
                break;
            }
            default: {
                break;
            }
        }

        const service = new GremService();
        service.updateUserLang({lang: userLang})
    }

    getIsoActiveLanguage(){
        switch (this.activeLanguage) {
            case 'rus': {
                return 'ru';
            }
            case 'ukr': {
                return  'ua';
            }
            case 'deu': {
                return 'de';
            }
            default: {
                return localStorage.getItem('language') ?? 'en'
            }
        }
    }
}

const languageStore = new LanguageStore();
export default languageStore;