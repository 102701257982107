import {observer} from "mobx-react-lite";
import {Box, Button, Grid, Link, Typography} from "@mui/material";
import {FileUploader} from "react-drag-drop-files";
import React from "react";
import globalDictionary from "assets/translations/globalDictionary";
import ImageCircle from "../PhotosLoader/image_circle.svg";
import ArrowUp from "assets/icons/arrow-up-from-arc.svg"
import ArrowToArc from "assets/icons/arrow-right-to-arc.svg"
import CircleCheck from "assets/icons/circle-check-green.svg"
import TrashBlank from "assets/icons/trash-blank-alt.svg"

const DocumentDropzone = observer(({
                                       config,
                                       uploadedFileCount,
                                       handleChange,
                                       deleteFile,
                                       downloadFile
                                   }) => {

    return (
        <Grid item xs={12} sm={12} md={12} lg={12}>
            <FileUploader
                disabled={uploadedFileCount >= config.maxCount}
                maxSize={config.size}
                multiple={false}
                handleChange={handleChange}
                name="file"
                types={config?.types ? config.types :['JPG', 'JPEG', 'PNG', 'WEBP', 'PDF','DOC', 'DOCX','ODT']}
            >
                <Box
                    sx={{
                        display: 'flex',
                        minWidth: '50px',
                        minHeight: '50px',
                        padding: '20px',
                        cursor: 'pointer',
                        flexWrap: 'wrap',
                        borderRadius: '5px',
                        border: config.maxCount === uploadedFileCount ? '1px solid #E0E0E0' : '1px dashed #E0E0E0',
                        background: '#FFF',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        flexDirection: config.vertical ? 'column' : {
                            xs: 'column',
                            sm: 'row'
                        },
                        gap: config.vertical ? '10px' : '0',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: config.vertical ? 'column' : {
                                xs: 'column',
                                sm: 'row'
                            },
                            alignItems: 'center',
                            flexWrap: 'wrap',
                            gap: '15px'
                        }}
                    >
                        <Typography
                            sx={{
                                color: '#021228',
                                fontFamily: 'Gilroy',
                                fontSize: '14px',
                                fontStyle: 'normal',
                                fontWeight: '700',
                                lineHeight: '150%',
                                textAlign: {
                                    xs: 'center',
                                }
                            }}
                        >
                            {config.documentDropzoneTitle ? config.documentDropzoneTitle : ''}
                        </Typography>
                        {config.maxCount === uploadedFileCount &&
                            <>
                                <img src={CircleCheck}/>
                                <Typography
                                    sx={{
                                        color: '#219653',
                                        fontSize: '14px'
                                    }}
                                >
                                    {globalDictionary.get('file_loader_drop_loaded')}
                                </Typography>
                                <Typography
                                sx={{

                                    color: '#021228',
                                    fontFamily: 'Gilroy',
                                    fontSize: '12px',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    lineHeight: '150%',
                                }}
                                >
                                    {config.fileName && config.fileName}
                                </Typography>
                            </>
                        }
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            flexDirection: config.vertical ? 'column' : {
                                xs: 'column',
                                sm: 'row'
                            },
                        }}
                    >

                        {
                            uploadedFileCount === config.maxCount ?
                                (
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center'
                                        }}
                                    >

                                        <Typography
                                            sx={{
                                                fontSize: '14px',
                                                color: '#939393',
                                                lineHeight: '150%'
                                            }}
                                            onClick={() => {
                                                downloadFile();
                                            }}
                                        >
                                            {globalDictionary.get('file_loader_drop_open_file')}
                                        </Typography>
                                        <img src={ArrowToArc}
                                             style={{
                                                 marginRight: '30px',
                                                 marginLeft: '15px',
                                                 cursor: 'pointer'
                                             }}
                                             onClick={() => {
                                                 downloadFile();
                                             }}
                                        />
                                        <img src={TrashBlank}
                                             style={{
                                                 cursor: 'pointer'
                                             }}
                                             onClick={() => {
                                                 deleteFile(0);
                                             }}
                                        />
                                    </Box>
                                )
                                : (
                                    <Box sx={{
                                        display: 'flex',
                                        flexDirection: config.vertical ? 'column' : {
                                            xs: 'column',
                                            sm: 'row'
                                        },
                                        alignItems: 'center',
                                        gap: config.vertical ? '35px' : '15px',
                                        mt: {
                                            xs: '10px',
                                            sm: '0'
                                        }
                                    }}>
                                        <Box sx={{
                                            display: 'flex',
                                            gap: '10px',
                                        }}>
                                            <img src={ArrowUp} width={20} height={20}/>
                                            <Typography sx={{
                                                color: '#939393',
                                                fontFamily: 'Gilroy',
                                                fontSize: '14px',
                                                fontStyle: 'normal',
                                                fontWeight: '400',
                                                lineHeight: '150%',
                                                marginLeft: '15px',
                                                marginRight: '30px',
                                                textAlign: {
                                                    xs: 'center',
                                                }
                                            }}>
                                                {config.documentDropzoneSubTitle ? config.documentDropzoneSubTitle : globalDictionary.get('photo_loader_drop_document')}
                                            </Typography>
                                        </Box>

                                        <Button variant={'contained'}
                                        >
                                            {
                                                config.buttonIcon !== false ?
                                                    (
                                                        config.buttonIcon ? config.buttonIcon :
                                                            <img src={ImageCircle}/>
                                                    ) : ''
                                            }
                                            <Typography
                                                ml={'10px'}
                                                sx={{
                                                    fontSize: '14px'
                                                }}
                                            >
                                                {config.buttonText ? config.buttonText : globalDictionary.get('photos_loader_upload_document')}
                                            </Typography>
                                        </Button>
                                    </Box>
                                )
                        }
                    </Box>
                </Box>
            </FileUploader>
        </Grid>
    )


})

export default DocumentDropzone;
