import { makeAutoObservable, reaction } from 'mobx';
import { ChatSocketService, NotificationSocketService } from 'services';

class Websocket {
  chat = null;
  notification = null;
  reconnectInterval = 5000;
  auth = null;
  manualClose = false;

  constructor(props) {
    makeAutoObservable(this);

    this.auth = props.auth;

    reaction(
        () => this.auth.user,
        (user) => {
          const { userToken: token } = user || {};
          if (token) {
            this.manualClose = false;
            this.init(token).then(() => {});
          } else {
            this.stopAll();
          }
        }
    );

    document.addEventListener('visibilitychange', this.handleVisibilityChange);
  }

  async init(token) {
    this.stopAll();

    try {
      this.chat = await ChatSocketService.init(token);
      this.notification = await NotificationSocketService.init(token);

      this.addReconnectHandler(this.chat, token, ChatSocketService);
      this.addReconnectHandler(this.notification, token, NotificationSocketService);

      this.addDataHandlers();
    } catch (error) {
      console.error('Failed to initialize sockets:', error);
      setTimeout(() => this.init(token), this.reconnectInterval);
    }
  }

  addReconnectHandler(service, token, ServiceClass) {
    service.socket.onclose = (reason) => {
      if (!this.manualClose) {
        console.log('Connection lost, attempting to reconnect...' + reason);
        this.reconnect(service, token, ServiceClass);
      } else {
        console.log('Manual close, no reconnection attempt.');
      }
    };
  }

  addDataHandlers() {
    if (this.notification) {
      this.notification.socket.on('notifications', (data) => {
        this.notification.notifications = data.data;
      });

      this.notification.socket.on('counters', (data) => {
        this.notification.counter = data;
      });
    }
  }

  async reconnect(service, token, ServiceClass) {
    if (this.manualClose) {
      console.log('Manual close detected, cancelling reconnection.');
      return;
    }

    if (!this.auth.user || !this.auth.user.userToken) {
      console.log('No valid token available for reconnection.');
      return;
    }

    service= {...service,socket:null};

    setTimeout(async () => {
      console.log('Reconnecting...');
      try {
        const newService = await ServiceClass.init(token);
        if (ServiceClass === ChatSocketService) {
          this.chat = newService;
        } else if (ServiceClass === NotificationSocketService) {
          this.notification = newService;
        }
        this.addReconnectHandler(newService, token, ServiceClass);
        this.addDataHandlers();
        console.log('Reconnected successfully.');
      } catch (error) {
        console.error('Reconnect failed, trying again...', error);
        await this.reconnect(service, token, ServiceClass);
      }
    }, this.reconnectInterval);
  }

  handleVisibilityChange = () => {
    console.log('check')
    if (document.visibilityState === 'visible') {
      this.checkSocketStatus();
    }
  };

  stopAll() {
    this.manualClose = true;

    if (this.chat) {
      this.chat.socket.close();
    }
    if (this.notification) {
      this.notification.socket.close();
    }

    this.chat = null;
    this.notification = null;
  }

  // Метод для проверки статуса сокетов и их инициализации при необходимости
  checkSocketStatus() {
    if (!this.chat || this?.chat?.socket?.connected === false) {
      this.reconnect(this.chat, this.auth.user.userToken, ChatSocketService);
    }
    if (!this.notification || this?.notification?.socket?.connected === false) {
      console.log('Notification socket is not active, reinitializing...');
      this.reconnect(this.notification, this.auth.user.userToken, NotificationSocketService);
    }
  }
}

export default Websocket;
