import {observer} from "mobx-react-lite";
import {Box, Button, Grid, Typography} from "@mui/material";
import {FileUploader} from "react-drag-drop-files";
import React, {Fragment} from "react";
import globalDictionary from "assets/translations/globalDictionary";

const ImageDropzone = observer(({config,uploadedFileCount,dropzone,handleChange})=>{

    return(
        <>

    {config.maxCount !== uploadedFileCount &&
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <FileUploader
                    disabled={uploadedFileCount >= config.maxCount}
                    children={
                        <Box
                            sx={{
                                position: 'relative',
                                display: 'flex',
                                flexDirection: 'column',
                                minWidth: '50px',
                                minHeight: '50px',
                                padding: '20px 16px 8px 8px',
                                cursor: 'pointer',
                                flexWrap: 'wrap',
                                color: '#939393'
                            }}
                        >
                            <Fragment>
                                <Typography sx={{
                                    color: '#939393',
                                    fontFamily: 'Gilroy',
                                    fontSize: '14px',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    lineHeight: '150%'
                                }}>
                                    {globalDictionary.get('photo_loader_drop')}
                                </Typography>
                                <Grid container gridRow>
                                    <Typography
                                        sx={{
                                            fontSize: '14px',
                                            fontStyle: 'normal',
                                            fontWeight: '400',
                                            lineHeight: '150%'
                                        }}
                                    >
                                        {config.minCount > 0 && (`${globalDictionary.get('photo_loader_file_count_min')} ${
                                            config.minCount
                                        } ${globalDictionary.get(config.minKey)}. `)}
                                        {`${globalDictionary.get('photo_loader_file_count',)} ${
                                            config.maxCount
                                        } ${globalDictionary.get(config.maxKey)}. `}
                                        {`${globalDictionary.get('photo_loader_file_size')} - ${
                                            config.size
                                        }Mb. `}
                                        {globalDictionary.get('photo_loader_formats')}
                                    </Typography>
                                </Grid>
                            </Fragment>

                        </Box>
                    }
                    maxSize={config.size}
                    multiple={config.maxCount > 1}
                    handleChange={handleChange}
                    name="file"
                    types={config?.types ? config.types :['JPG', 'JPEG', 'PNG', 'WEBP']}
                />
            </Grid>
        }
        </>
    )

})

export default ImageDropzone;