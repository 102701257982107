import {observer} from "mobx-react-lite";
import {
    Box,
    Button,
    Checkbox,
    FormControl,
    Grid, IconButton,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography
} from "@mui/material";
import {ImageChecker} from "components";
import {FileUploader} from "react-drag-drop-files";
import globalDictionary from "assets/translations/globalDictionary";
import countries from "../../../../../assets/translations/countries";
import React from "react";
import {useFormStore} from "../../../../../components/shared/DraftForm/FormStore";
import {PhoneInput} from "../../../../../components";
import {languages} from "../../../../../assets/translations/languagesList";
import penClipIcon from 'assets/icons/pen-clip.svg';

const UserStep = observer(()=>{
    const userStore = useFormStore();
    console.log(userStore)
return (
    <Grid container gridRow spacing={'30px'} sx={{
        display: 'flex',
        justifyContent: {
            xs: 'center',
            md: 'flex-start'
        }
    }}>
        <Grid item>
            <Box sx={{
                maxWidth: '200px',
                display: 'flex',
                flexDirection: 'column',
                gap: '20px',
            }}>

                <FileUploader
                    handleChange={(file) => {
                        userStore.uploadFile('avatar', file)
                    }}
                    children={
                        <IconButton
                            edge="end"
                            color="primary"
                            size="large"
                            sx={{
                                position: 'absolute',
                                right: '20px',
                                top: '28px',
                                backgroundColor: '#DEDEDE',
                                width: '36px',
                                height: '36px',
                                borderRadius: '50%',
                                padding: '5px',
                                zIndex: 1,
                            }}>
                            <img src={penClipIcon} alt={'edit'} width={16} height={16}/>
                        </IconButton>
                    }
                    name="avatar"
                    types={['JPG', 'JPEG', 'PNG','WEBP']}
                />
                <ImageChecker
                    src={userStore.fields.avatar}
                    variant={'rounded'}
                    sx={{
                        width: 200,
                        height: 200,
                    }}
                />
            </Box>
        </Grid>
        <Grid item xs={8}>
            <Typography variant={'h6'} marginBottom={'10px'}>
                {globalDictionary.get('profile_verification_user_title')}
            </Typography>
            <Grid item xs={12} container columnSpacing={"30px"} rowSpacing={'20px'}>
                <Grid item xs={12} sm={12} md={6}>
                    <TextField
                        label={globalDictionary.get('email')}
                        fullWidth
                        disabled
                        name={'email'}
                        type={'email'}
                        value={userStore.fields.email}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                    <TextField
                        label={globalDictionary.get('profile_verification_user_fname')}
                        fullWidth
                        name={'fname'}
                        type={'text'}
                        inputProps={{restrictSpecialChars:'true'}}
                        disabled={userStore.isEdit}
                        value={userStore.fields.fname}
                        onChange={userStore.handleInputChange}
                        error={!!userStore.fieldsErrors.fname}
                        helperText={userStore.fieldsErrors.fname}
                    />
                </Grid>
                <Grid item xs={12}  sm={12} md={6}>
                    <PhoneInput
                        placeholder={globalDictionary.get('profile_verification_user_phone')}
                        label={globalDictionary.get('profile_verification_user_phone')}
                        variant="outlined"
                        fullWidth
                        type={'phone'}
                        value={userStore.fields.phone}
                        name={'phone'}
                        onChange={(phone)=>{userStore.handleInputField('phone',phone)}}
                    />
                </Grid>
                <Grid item xs={12}  sm={12} md={6}>
                    <TextField
                        label={globalDictionary.get('profile_verification_user_lname')}
                        fullWidth
                        name={'lname'}
                        type={'text'}
                        inputProps={{restrictSpecialChars:'true'}}
                        disabled={userStore.isEdit}
                        value={userStore.fields.lname}
                        onChange={userStore.handleInputChange}
                        error={!!userStore.fieldsErrors.lname}
                        helperText={userStore.fieldsErrors.lname}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                    <FormControl variant="outlined" fullWidth>
                        <InputLabel id="select-country">
                            {globalDictionary.get('profile_verification_user_country')}
                        </InputLabel>
                        <Select
                            variant="outlined"
                            labelId="select-country"
                            id="select-type"
                            label='country'
                            required
                            value={userStore.fields.address.country}
                            name={'address.country'}
                            onChange={userStore.handleInputChange}
                            error={!!userStore?.fieldsErrors?.address?.country}
                            helperText={userStore?.fieldsErrors?.address?.country && userStore.fieldsErrors.address.country}
                        >
                            {countries.getAllValueLabel().map((type) => (
                                <MenuItem key={type.value} value={type.value}>
                                    {type.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                    <TextField
                        label={globalDictionary.get('location_city')}
                        fullWidth
                        name={'address.city'}
                        type={'text'}
                        inputProps={{restrictSpecialChars:'true'}}
                        value={userStore.fields.address.city}
                        onChange={userStore.handleInputChange}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                    <FormControl variant="outlined" fullWidth>
                        <InputLabel id="select-lang">
                            {globalDictionary.get('contact_language')}
                        </InputLabel>
                        <Select
                            variant="outlined"
                            labelId="select-lang"
                            id="select-type"
                            label={globalDictionary.get('contact_language')}
                            required
                            multiple
                            value={userStore.fields.contactLanguage ?? []}
                            name={'contactLanguage'}
                            renderValue={(selected) => selected.join(', ')}
                        >
                            {languages.map(item=>{
                                return <MenuItem value={item.name}
                                onClick={()=>{
                                    userStore.handleAddRemoveArrayElem('contactLanguage',item.name)
                                }}
                                >
                                   <Checkbox
                                       checked={userStore.fields.contactLanguage.includes(item.name)}
                                       sx={{
                                        marginRight:'15px',
                                           width:'15px',
                                           height:'15px',
                                           padding:'0px',
                                       }}
                                   />
                                    <Typography sx={{fontSize:'14px'}}>
                                    {item.nativeName}
                                    </Typography>
                                </MenuItem>
                            })}

                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
        </Grid>
    </Grid>
)

})

export default UserStep;
