import React, {useEffect, useState} from 'react';
import {Outlet} from 'react-router-dom';
import {styled} from '@mui/material/styles';
import Box from '@mui/material/Box';
import {CircularProgress, CssBaseline} from '@mui/material';
import Header from './components/Header/Header';
import DrawerMenu from './components/DrawerMenu/DrawerMenu';
import {useStore} from 'Providers';
import {
    getDrawerMenuFromLS,
    getUserInfoFromLocalStorage,
    setDrawerMenuToLS,
} from 'utils/index';
import {ScrollTop} from 'components/index';
import {Waypoint} from 'react-waypoint';

const Main = styled('main', {
    shouldForwardProp: (prop) => prop !== 'open',
})(({theme, open}) => ({
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: 0,
    marginTop: '40px',
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    }),
}));

function CabinetLayout() {
    const drawerState = getDrawerMenuFromLS();
    const [open, setOpen] = useState(
        typeof drawerState === 'object' ? true : drawerState === 'true'
    );
    const {auth} = useStore();
    const [user, setUser] = useState(getUserInfoFromLocalStorage());
    const drawerMenuWidth = 290;
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        let timeoutId;

        async function fetchUserInfo() {
            if(auth.isError === false) {
                if (!auth.user && user) {
                    setLoading(true);
                    await auth.getUser(user.userId);
                    setLoading(false);
                }
            } else {

                if (!timeoutId) {
                    timeoutId = setTimeout(async () => {
                        await auth.getUser(user.userId);
                    }, 12000);
                }
            }
        }

        fetchUserInfo();


        return () => clearTimeout(timeoutId);
    }, [auth.isError, auth.user, user]);

    const handleDrawerOpen = () => {
        setOpen(true);
        setDrawerMenuToLS(true);
    };
    const handleDrawerClose = () => {
        setOpen(false);
        setDrawerMenuToLS(false);
    };

    const handleWaypointEnter = () => {
        const widgetBtn = document.getElementById('widget-btn');
        if (widgetBtn) widgetBtn.style.display = 'block';
    };

    const handleWaypointLeave = () => {
        const widgetBtn = document.getElementById('widget-btn');
        if (widgetBtn) widgetBtn.style.display = 'none';
    };

    return (
        <Box sx={{display: 'flex'}}>
            <CssBaseline/>
            <Header
                handleDrawerOpen={handleDrawerOpen}
                open={open}
                drawerWidth={drawerMenuWidth}
                user={user}
            />
            <DrawerMenu
                handleDrawerOpen={handleDrawerOpen}
                handleDrawerClose={handleDrawerClose}
                open={open}
                drawerWidth={drawerMenuWidth}
            />
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    width: '0',
                    minHeight: '100vh',
                    flexGrow: 1,
                }}
            >
                <Main open={open}>
                    {loading ? (
                        <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%'}}>
                            <CircularProgress/>
                        </Box>
                    ) : (
                        <Outlet/>
                    )
                    }
                    <Waypoint
                        topOffset={'-50%'}
                        bottomOffset={'100%'}
                        onEnter={handleWaypointEnter}
                        onLeave={handleWaypointLeave}
                    />
                    <ScrollTop/>
                </Main>
            </Box>
        </Box>
    );
}

export default CabinetLayout;
